/* General Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f4a82a; /* Orange color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .logo {
  display: flex;
  align-items: center;
}

.navbar .logo img {
  height: 50px;
  margin-right: 0.5rem;
}

.navbar .logo span {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
}

.navbar .nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding-left: 0.5rem;
}

.navbar .nav-links li {
  display: inline-block;
}

.navbar .nav-links li a {
  text-decoration: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.navbar .nav-links li a:hover {
  color: #004f23; /* Dark green hover effect */
}

/* Hamburger Menu Button */
.hamburger {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

/* Mobile-Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger button */
  }

  .nav-links {
    display: none; /* Hidden by default */
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #f4a82a; /* Match navbar color */
    width: 100%;
    text-align: center;
    padding: 0;
    max-height: 0; /* Start collapsed */
    overflow: hidden; /* Hide overflowing links */
    transition: max-height 0.3s ease-in-out; /* Smooth open/close transition */
  }
  
  .nav-links.active {
    display: flex;
    max-height: 500px; /* Adjust based on expected content */
    padding: 1rem 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links li {
    margin: 0.5rem 0;
  }
}

/* Language Selector Styling */
.language-selector {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  color: inherit;
  font-size: 14px;
  cursor: pointer;
}

.language-selector:hover {
  border-color: #666;
}

.language-selector option {
  background-color: white;
  color: black;
}