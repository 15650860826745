.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding-top: 80px; /* Adjust this value to match or slightly exceed the navbar height */
}



.text-container {
  margin-top: 20px; /* Add additional space below the navbar */
  width: 70%;
  max-width: 70%;
  margin: 0 auto;
  text-align: left;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .text-container {
    width: 90%; /* Use 90% width for screens smaller than 768px */
  }
}

.page-title {
  font-size: 2.5rem;
  color: #006837; /* Green color to match the branding */
  margin-bottom: 1rem;
}

.section-title {
  font-size: 1.8rem;
  color: #f4a82a; /* Orange color from the logo */
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.bus-schedule p,
.ride-plan p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  color: #333;
}

.schedule-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.schedule-list li {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: #004f23; /* Dark green */
}

.how-it-works-list {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.how-it-works-list li {
  margin: 0.8rem 0;
  font-size: 1.1rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Add spacing between the images */
  margin-top: 1rem; /* Space above the images */
}

@media (max-width: 768px) {
  .image-container {
    width: 90%; /* Expand the image to 90% of the container on small screens */
    max-width: none; /* Allow it to grow beyond the previous max-width */
    margin-bottom: 1rem; /* Add spacing between stacked images */
  }
}

.example-image {
  width: 45%; /* Make the images take up 45% of the container's width */
  max-width: 300px; /* Optional: Set a max width for larger screens */
  height: auto; /* Maintain the aspect ratio */
  
}

@media (max-width: 768px) {
  .example-image {
    width: 90%; /* Expand the image to 90% of the container on small screens */
    max-width: none; /* Allow it to grow beyond the previous max-width */
    margin-bottom: 1rem; /* Add spacing between stacked images */
  }
}