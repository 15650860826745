.home {
  text-align: center;
  padding: 2rem;
}

.home h1 {
  color: #006837; /* Green color from the logo */
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home p {
  color: #333333;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.home-logo {
  height: 300px;
  border-radius: 10px;
}